// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-dr-kaloti-js": () => import("../src/pages/about-dr-kaloti.js" /* webpackChunkName: "component---src-pages-about-dr-kaloti-js" */),
  "component---src-pages-advanced-technology-js": () => import("../src/pages/advanced-technology.js" /* webpackChunkName: "component---src-pages-advanced-technology-js" */),
  "component---src-pages-ambassador-program-js": () => import("../src/pages/ambassador-program.js" /* webpackChunkName: "component---src-pages-ambassador-program-js" */),
  "component---src-pages-banner-js": () => import("../src/pages/banner.js" /* webpackChunkName: "component---src-pages-banner-js" */),
  "component---src-pages-before-after-js": () => import("../src/pages/before-after.js" /* webpackChunkName: "component---src-pages-before-after-js" */),
  "component---src-pages-blog-5-ways-to-detect-oral-cancer-js": () => import("../src/pages/blog/5-ways-to-detect-oral-cancer.js" /* webpackChunkName: "component---src-pages-blog-5-ways-to-detect-oral-cancer-js" */),
  "component---src-pages-blog-achieve-a-celebrity-smile-js": () => import("../src/pages/blog/achieve-a-celebrity-smile.js" /* webpackChunkName: "component---src-pages-blog-achieve-a-celebrity-smile-js" */),
  "component---src-pages-blog-bachelor-paradise-smiles-js": () => import("../src/pages/blog/bachelor-paradise-smiles.js" /* webpackChunkName: "component---src-pages-blog-bachelor-paradise-smiles-js" */),
  "component---src-pages-blog-best-smile-on-bachelor-js": () => import("../src/pages/blog/best-smile-on-bachelor.js" /* webpackChunkName: "component---src-pages-blog-best-smile-on-bachelor-js" */),
  "component---src-pages-blog-better-smile-better-life-js": () => import("../src/pages/blog/better-smile-better-life.js" /* webpackChunkName: "component---src-pages-blog-better-smile-better-life-js" */),
  "component---src-pages-blog-essential-things-about-your-dogs-oral-health-js": () => import("../src/pages/blog/essential-things-about-your-dogs-oral-health.js" /* webpackChunkName: "component---src-pages-blog-essential-things-about-your-dogs-oral-health-js" */),
  "component---src-pages-blog-index-js": () => import("../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-name-that-hollywood-smile-js": () => import("../src/pages/blog/name-that-hollywood-smile.js" /* webpackChunkName: "component---src-pages-blog-name-that-hollywood-smile-js" */),
  "component---src-pages-blog-secrets-of-a-photogenic-smile-js": () => import("../src/pages/blog/secrets-of-a-photogenic-smile.js" /* webpackChunkName: "component---src-pages-blog-secrets-of-a-photogenic-smile-js" */),
  "component---src-pages-blog-smile-makeover-for-better-life-js": () => import("../src/pages/blog/smile-makeover-for-better-life.js" /* webpackChunkName: "component---src-pages-blog-smile-makeover-for-better-life-js" */),
  "component---src-pages-blog-styling-your-teeth-to-your-smile-js": () => import("../src/pages/blog/styling-your-teeth-to-your-smile.js" /* webpackChunkName: "component---src-pages-blog-styling-your-teeth-to-your-smile-js" */),
  "component---src-pages-blog-the-confidence-behind-a-smile-js": () => import("../src/pages/blog/the-confidence-behind-a-smile.js" /* webpackChunkName: "component---src-pages-blog-the-confidence-behind-a-smile-js" */),
  "component---src-pages-blog-who-has-the-best-smiles-on-the-bachelorette-js": () => import("../src/pages/blog/who-has-the-best-smiles-on-the-bachelorette.js" /* webpackChunkName: "component---src-pages-blog-who-has-the-best-smiles-on-the-bachelorette-js" */),
  "component---src-pages-blog-why-men-are-getting-more-cosmetic-dental-js": () => import("../src/pages/blog/why-men-are-getting-more-cosmetic-dental.js" /* webpackChunkName: "component---src-pages-blog-why-men-are-getting-more-cosmetic-dental-js" */),
  "component---src-pages-blog-why-more-people-are-choosing-clear-correct-invisible-braces-js": () => import("../src/pages/blog/why-more-people-are-choosing-clear-correct-invisible-braces.js" /* webpackChunkName: "component---src-pages-blog-why-more-people-are-choosing-clear-correct-invisible-braces-js" */),
  "component---src-pages-blog-your-smile-and-dating-js": () => import("../src/pages/blog/your-smile-and-dating.js" /* webpackChunkName: "component---src-pages-blog-your-smile-and-dating-js" */),
  "component---src-pages-botox-js": () => import("../src/pages/botox.js" /* webpackChunkName: "component---src-pages-botox-js" */),
  "component---src-pages-ceramic-crowns-js": () => import("../src/pages/ceramic-crowns.js" /* webpackChunkName: "component---src-pages-ceramic-crowns-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dental-implants-js": () => import("../src/pages/dental-implants.js" /* webpackChunkName: "component---src-pages-dental-implants-js" */),
  "component---src-pages-dr-kalotis-book-js": () => import("../src/pages/dr-kalotis-book.js" /* webpackChunkName: "component---src-pages-dr-kalotis-book-js" */),
  "component---src-pages-financing-js": () => import("../src/pages/financing.js" /* webpackChunkName: "component---src-pages-financing-js" */),
  "component---src-pages-full-mouth-reconstruction-js": () => import("../src/pages/full-mouth-reconstruction.js" /* webpackChunkName: "component---src-pages-full-mouth-reconstruction-js" */),
  "component---src-pages-gaps-between-teeth-js": () => import("../src/pages/gaps-between-teeth.js" /* webpackChunkName: "component---src-pages-gaps-between-teeth-js" */),
  "component---src-pages-giving-back-js": () => import("../src/pages/giving-back.js" /* webpackChunkName: "component---src-pages-giving-back-js" */),
  "component---src-pages-gum-lift-procedures-js": () => import("../src/pages/gum-lift-procedures.js" /* webpackChunkName: "component---src-pages-gum-lift-procedures-js" */),
  "component---src-pages-in-office-bonding-js": () => import("../src/pages/in-office-bonding.js" /* webpackChunkName: "component---src-pages-in-office-bonding-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invisible-braces-js": () => import("../src/pages/invisible-braces.js" /* webpackChunkName: "component---src-pages-invisible-braces-js" */),
  "component---src-pages-partner-with-us-js": () => import("../src/pages/partner-with-us.js" /* webpackChunkName: "component---src-pages-partner-with-us-js" */),
  "component---src-pages-policy-js": () => import("../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-porcelain-veneers-js": () => import("../src/pages/porcelain-veneers.js" /* webpackChunkName: "component---src-pages-porcelain-veneers-js" */),
  "component---src-pages-press-release-dream-wedding-contest-js": () => import("../src/pages/press-release/dream-wedding-contest.js" /* webpackChunkName: "component---src-pages-press-release-dream-wedding-contest-js" */),
  "component---src-pages-press-release-get-ready-to-meet-your-smile-stylist-js": () => import("../src/pages/press-release/get-ready-to-meet-your-smile-stylist.js" /* webpackChunkName: "component---src-pages-press-release-get-ready-to-meet-your-smile-stylist-js" */),
  "component---src-pages-press-release-index-js": () => import("../src/pages/press-release/index.js" /* webpackChunkName: "component---src-pages-press-release-index-js" */),
  "component---src-pages-press-release-more-than-a-smile-js": () => import("../src/pages/press-release/more-than-a-smile.js" /* webpackChunkName: "component---src-pages-press-release-more-than-a-smile-js" */),
  "component---src-pages-press-release-oakville-club-magazine-js": () => import("../src/pages/press-release/oakville-club-magazine.js" /* webpackChunkName: "component---src-pages-press-release-oakville-club-magazine-js" */),
  "component---src-pages-press-release-tennis-canada-rogers-cup-js": () => import("../src/pages/press-release/tennis-canada-rogers-cup.js" /* webpackChunkName: "component---src-pages-press-release-tennis-canada-rogers-cup-js" */),
  "component---src-pages-register-js": () => import("../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-selecting-cosmetic-dentists-js": () => import("../src/pages/selecting-cosmetic-dentists.js" /* webpackChunkName: "component---src-pages-selecting-cosmetic-dentists-js" */),
  "component---src-pages-services-js": () => import("../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-spa-dentistry-js": () => import("../src/pages/spa-dentistry.js" /* webpackChunkName: "component---src-pages-spa-dentistry-js" */),
  "component---src-pages-subscribe-js": () => import("../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-teeth-whitening-js": () => import("../src/pages/teeth-whitening.js" /* webpackChunkName: "component---src-pages-teeth-whitening-js" */),
  "component---src-pages-testimonials-js": () => import("../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thank-you-js": () => import("../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-virtual-smile-makeover-js": () => import("../src/pages/virtual-smile-makeover.js" /* webpackChunkName: "component---src-pages-virtual-smile-makeover-js" */),
  "component---src-pages-white-fillings-js": () => import("../src/pages/white-fillings.js" /* webpackChunkName: "component---src-pages-white-fillings-js" */)
}

